//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img_dir: "../img";

// コンテンツ幅
$content_width: 1200px;

// SP時の左右の余白（片方）
$sp_side_padding: 20px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc_bp以上がPC表示
$pc_bp: 1025px;

// $sp_bp以下がSP表示
$sp_bp: 767px;

//=================================
// font
//=================================
$font_1: "游明朝", "YuMincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font_2: "Times", "游明朝", "YuMincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font_3: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;

//=================================
// color
//=================================
$black: #000;

$white: #fff;

$color_1: #a68a5f;

$color_2: #91692b;

$color_3: #a2a2a2;

$color_4: #333;

$color_5: #151515;

$color_6: #3b3b3b;

$color_7: #39270b;

$color_8: #5a4b33;

$color_9: #464646;

$color_10: #222;

$color_11: #cbb188;

$color_12: #b9b9b9;

$color_13: #707070;

$color_14: #4b4b4b;

$color_15: #2e2e2e;

$color_16: #3e3528;

$color_17: #ff6157;

$color_18: #363636;

$color_19: #002050;

$color_20: #666;

$color_21: #365686;

$color_22: #324b72;

$color_23: #f0ede9;

//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(.250, .250, .750, .750);
$ease: cubic-bezier(.250, .100, .250, 1.000);
$ease-in: cubic-bezier(.420, .000, 1.000, 1.000);
$ease-out: cubic-bezier(.000, .000, .580, 1.000);
$ease-in-out: cubic-bezier(.420, .000, .580, 1.000);
// In
$easeInQuad: cubic-bezier(.550, .085, .680, .530);
$easeInCubic: cubic-bezier(.550, .055, .675, .190);
$easeInQuart: cubic-bezier(.895, .030, .685, .220);
$easeInQuint: cubic-bezier(.755, .050, .855, .060);
$easeInSine: cubic-bezier(.470, .000, .745, .715);
$easeInExpo: cubic-bezier(.950, .050, .795, .035);
$easeInCirc: cubic-bezier(.600, .040, .980, .335);
$easeInBack: cubic-bezier(.600, -.280, .735, .045);
// Out
$easeOutQuad: cubic-bezier(.250, .460, .450, .940);
$easeOutCubic: cubic-bezier(.215, .610, .355, 1.000);
$easeOutQuart: cubic-bezier(.165, .840, .440, 1.000);
$easeOutQuint: cubic-bezier(.230, 1.000, .320, 1.000);
$easeOutSine: cubic-bezier(.390, .575, .565, 1.000);
$easeOutExpo: cubic-bezier(.190, 1.000, .220, 1.000);
$easeOutCirc: cubic-bezier(.075, .820, .165, 1.000);
$easeOutBack: cubic-bezier(.175, .885, .320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(.455, .030, .515, .955);
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1.000);
$easeInOutQuart: cubic-bezier(.770, .000, .175, 1.000);
$easeInOutQuint: cubic-bezier(.860, .000, .070, 1.000);
$easeInOutSine: cubic-bezier(.445, .050, .550, .950);
$easeInOutExpo: cubic-bezier(1.000, .000, .000, 1.000);
$easeInOutCirc: cubic-bezier(.785, .135, .150, .860);
$easeInOutBack: cubic-bezier(.680, -.550, .265, 1.550);
