@import "variable";
@import "mixin";

/* ======================================================================
 p_404
====================================================================== */
.l_container {
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    content: "";

    @include sp {
      background-image: url(#{$img_dir}/p_top/sp/sec1_bg.jpg);
    }
    @include pc_tab {
      background-image: url(#{$img_dir}/p_top/sec1_bg.jpg);
    }
  }
}
.l_breadcrumbs {
  @include sp {
    padding: 0 0 60px;
  }
  @include pc_tab {
    padding: 0 0 80px;
  }
  &.u_bg_1 {
    background: transparent;
  }
}
.l_content {
  @include sp {
    margin-top: 66px;
    padding: 18px 0 0;
  }
  @include pc_tab {
    padding: 100px 0 0;
  }
}
.deco_top {
  position: absolute;
  z-index: 1;
  background-position: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  mix-blend-mode: color-dodge;
  @include sp {
    top: -119px;
    left: 0;
    width: 100vw;
    height: 100.3vw;
    background-image: url(#{$img_dir}/common/sp/deco_top.png);
  }
  @include pc_tab {
    top: -108px;
    left: 0;
    width: 925px;
    height: 940px;
    background-image: url(#{$img_dir}/common/deco_top.png);
  }
}
.deco_bottom {
  @include sp {
    display: none;
  }
  @include pc_tab {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 707px;
    height: 425px;
    background-image: url(#{$img_dir}/common/deco_bottom.png);
    background-position: top left;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    mix-blend-mode: color-dodge;
  }
}
.sec {
  position: relative;
  z-index: 3;
  &._1 {
    .u_content_size {
      max-width: 900px;
    }
    .list_box {
      margin: 40px auto;

      @include sp {
        padding: 25px 20px;
      }
      @include pc_tab {
        padding: 30px 20px;
      }
    }
    .c_list_1 {
      display: inline-block;
    }
    .c_btn_1_wrap {
      @include sp {
        margin: 40px auto 0;
      }
      @include pc_tab {
        margin: 60px auto 0;
      }
      .c_btn_1 {
        @include pc_tab {
          width: 260px;
        }
      }
    }
  }
  &._2 {
    position: relative;
    background-color: $black;

    @include sp {
      margin: 60px 0 0;
    }
    @include pc_tab {
      margin: 125px 0 0;
    }
    &::before {
      position: absolute;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      background-position: top left;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      content: "";

      @include sp {
        top: -156px;
        height: 339px;
        background-image: url(#{$img_dir}/common/sp/gradation_3.png);
      }
      @include pc_tab {
        top: -196px;
        height: 339px;
        background-image: url(#{$img_dir}/common/gradation_3.png);
      }
    }
  }
}

.c_cta._1 .u_content_size {
  padding-top: 0;
}
